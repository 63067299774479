var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:[
    _vm.openDyslexic ? 'open-dyslexic' : '',
    _vm.$route.path === '/welcome' || _vm.$route.path == '/terms' ? 'border' : '',
  ]},[_c('div',{class:[
      'app-background',
      {
        'app-background--large':
          _vm.$route.name === 'welcome' ||
          _vm.$route.name === 'home' ||
          _vm.$route.name === 'sign_in' ||
          _vm.$route.name === 'sign_up' ||
          _vm.$route.name === 'surveys' ||
          _vm.$route.name === 'posts' ||
          _vm.$route.name === 'chats' ||
          _vm.$route.name === 'user_profile' ||
          _vm.$route.name === 'user_edit' ||
          _vm.$route.name === 'blocked_users',
      },
    ]}),(_vm.$route.path != '/welcome')?_c('BypassBlock'):_vm._e(),(
      _vm.$route.path != '/welcome' &&
      _vm.$route.name != 'user_register' &&
      _vm.$route.path != '/terms'
    )?_c('Header'):_vm._e(),_c('v-main',{attrs:{"id":"main-content","tabindex":"-1"}},[_c('router-view',{key:_vm.$route.fullPath}),_c('v-alert',{staticClass:"network-error",attrs:{"dense":"","dismissible":"","type":"error"},model:{value:(_vm.networkAlert),callback:function ($$v) {_vm.networkAlert=$$v},expression:"networkAlert"}},[_vm._v("Network Error. Unable to fetch live data")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }