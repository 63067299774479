<template>
  <v-app
    :class="[
      openDyslexic ? 'open-dyslexic' : '',
      $route.path === '/welcome' || $route.path == '/terms' ? 'border' : '',
    ]"
  >
    <div
      :class="[
        'app-background',
        {
          'app-background--large':
            $route.name === 'welcome' ||
            $route.name === 'home' ||
            $route.name === 'sign_in' ||
            $route.name === 'sign_up' ||
            $route.name === 'surveys' ||
            $route.name === 'posts' ||
            $route.name === 'chats' ||
            $route.name === 'user_profile' ||
            $route.name === 'user_edit' ||
            $route.name === 'blocked_users',
        },
      ]"
    ></div>

    <BypassBlock v-if="$route.path != '/welcome'"></BypassBlock>

    <Header
      v-if="
        $route.path != '/welcome' &&
        $route.name != 'user_register' &&
        $route.path != '/terms'
      "
    >
    </Header>

    <v-main id="main-content" tabindex="-1">
      <router-view :key="$route.fullPath" />

      <v-alert
        v-model="networkAlert"
        dense
        dismissible
        type="error"
        class="network-error"
        >Network Error. Unable to fetch live data</v-alert
      >
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header/Header";
import BypassBlock from "./components/App/BypassBlock.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    Header,
    BypassBlock,
  },
  watch: {
    // '$route': 'currentRoute',
    $route: {
      immediate: true,
      handler(to) {
        if (to && to.name) {
          document.title = to.name.replace(/_|-|\./g, " ") || "App";
        } else {
          document.title = "App";
        }
      },
    },
  },
  data: () => ({
    routeName: null,
    alert: false,
  }),
  methods: {
    ...mapActions("hubbdata", ["updateLang", "setFont"]),
    ...mapActions("user", ["startPollingUserData"]),
    ...mapMutations("hubbdata", ["setNetworkAlert"]),
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name;
      });
    },
  },

  mounted() {
    if (localStorage.getItem("current_lang") !== null) {
      this.updateLang(localStorage.getItem("current_lang"));
    }

    this.setFont();

    if (this.isTokenSet) {
      this.startPollingUserData();
    }
  },
  computed: {
    ...mapGetters("hubbdata", ["translations", "openDyslexic"]),
    networkAlert: {
      get: function () {
        return this.$store.getters["hubbdata/getNetworkAlert"];
      },
      set: function (newValue) {
        this.setNetworkAlert(newValue);
      },
    },
  },
};
</script>
<style lang="scss">
.app-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 400px;
    max-height: 35%;
    background-image: url("./assets/images/top-splash.png");
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 100%;
  }

  &::before {
    top: 0;

    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 150px,
        rgba(255, 255, 255, 1) 150px
      ),
      url("./assets/images/top-splash.png");
  }

  &::after {
    bottom: 0;
    transform: rotate(180deg);
  }

  &--large {
    &:before {
      background-image: url("./assets/images/top-splash.png");
    }
  }

  @media (min-width: 960px) {
    &:before,
    &:after {
      left: 100px;
      height: 600px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
a {
  color: #0062ae !important;
  // text-decoration: underline !important;
}

*:focus {
  outline: #0062ae solid 3px;
}

// a:focus {
//   outline: auto;
// }

.v-list-item__content .v-list-item__content:focus-within,
.v-list--two-line .v-list-item__content:focus-within,
.icons:focus .v-card {
  outline: #0062ae solid 3px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  color: black !important;
  background-color: white;
}

.network-error {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  border-radius: 0 !important;
  font-weight: 700;

  @media (min-width: 960px) {
    left: 256px;
  }

  .v-btn {
    &:before {
      display: none;
    }

    &:focus,
    &:hover {
      outline: 2px solid white;
      outline-offset: 2px;
    }
  }
}

////// APP TOUR PLUGIN /////

.v-step {
  border: 4px solid $black !important;
  max-width: 280px !important;
  background-color: $white !important;
  border-radius: 20px !important;
  padding: 10px !important;
}

.v-step__header {
  border-bottom: 2px solid $black;
  font-size: 1.25rem;
  line-height: 1.3;
  color: $black;
  background-color: $white !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  margin: -10px -10px 10px !important;
  padding: 10px !important;
}

.v-step__content {
  font-size: 0.875rem;
  line-height: 1.5;
  color: $black;
}

.v-step__button {
  border: 3px solid $black !important;
  border-radius: 100px !important;
  background-color: $white !important;
  font-size: 0.875rem !important;
  line-height: 1.3 !important;
  color: $black !important;
  font-weight: 700 !important;
  text-align: center !important;
  padding: 2px 12px !important;
  text-decoration: none !important;
  height: auto !important;
  transition: none !important;

  &:focus {
    outline: 2px solid $black !important;
    outline-offset: 2px !important;
  }
}

.v-step__arrow::before {
  background-color: $black !important;
}
</style>
