/* eslint-disable */
export default {
  methods: {
    initOneSignal: function (selectedLocation) {
      // if cordova is present then the device being used should be a mobile
      if (window.cordova) {
        // NOTE: Update the setAppId value below with your OneSignal AppId.
        window.plugins.OneSignal.setAppId(
          "7e991e3a-bb92-41f1-ae43-7862f1caca21"
        );

        // function to handle the opening of a notification
        window.plugins.OneSignal.setNotificationOpenedHandler(function (
          jsonData
        ) {
          const redirectUrl = jsonData.notification.additionalData.redirectURL;

          if (redirectUrl) {
            window.location.replace(redirectUrl);
          }
        });

        // method to prompt the user for push notifications
        window.plugins.OneSignal.promptForPushNotificationsWithUserResponse();

        if (selectedLocation) {
          // method for sending a custom tag
          window.plugins.OneSignal.sendTag("location", selectedLocation);
        }
      }
    },
    setStatusBarStyles: function () {
      if (window.cordova) {
        // NOTE: Update the setAppId value below with your OneSignal AppId.
        window.plugins.OneSignal.setAppId(
          "7e991e3a-bb92-41f1-ae43-7862f1caca21"
        );
        // Setting the status bar to appear above the app content and not overlay
        StatusBar.overlaysWebView(false);
        // Setting the status bar background color
        StatusBar.backgroundColorByHexString("#000000");
        // Sets the status bar text color to a default dark color, which is suitable for light-colored backgrounds
        // StatusBar.styleDefault();
        // Sets the status bar text color to light, which is suitable for dark-colored backgrounds
        StatusBar.styleLightContent();
      }
    },
    updateOneSignalLocation: function (selectedLocation) {
      if (window.cordova) {
        window.plugins.OneSignal.setAppId(
          "7e991e3a-bb92-41f1-ae43-7862f1caca21"
        );
        window.plugins.OneSignal.sendTag("location", selectedLocation);
      }
    },
  },
};
/* eslint-enable */
